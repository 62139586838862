import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})
const axiosInsTest = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'http://api159.sriphat.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns
Vue.prototype.$http2 = axiosInsTest

export default axiosIns
