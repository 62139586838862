export default [
  {
    path: '/profileCreateAccount',
    name: 'member-account',
    component: () => import('@/views/profile/profileCreateAccount.vue'),
  },
  {
    path: '/profileBinding',
    name: 'member-binding',
    component: () => import('@/views/profile/profileBinding.vue'),
  },
  {
    path: '/home',
    name: 'member-home',
    component: () => import('@/views/profile/Profile.vue'),
  },
  {
    path: '/profile/:module',
    name: 'member-module',
    component: () => import('@/views/profile/Profile.vue'),
  },
  {
    path: '/profile',
    name: 'member-profile',
    component: () => import('@/views/profile/ProfilePatientInfo.vue'),
  },
  {
    path: '/appointment',
    name: 'member-appointment',
    component: () => import('@/views/profile/ProfileAppointmentList.vue'),
  },
  {
    path: '/medical-history',
    name: 'member-history',
    component: () => import('@/views/profile/ProfileMedicalHistoryList.vue'),
  },
  {
    path: '/medical-history/:id',
    name: 'member-medical-detail',
    component: () => import('@/views/profile/ProfileMedicalHistoryDetail.vue'),
  },
  {
    path: '/medical-vital-sign-body-mass',
    name: 'member-vital-sign-body-mass',
    component: () => import('@/views/profile/ProfileVitalSignBodyMassFull.vue'),
  },
  {
    path: '/medical-vital-sign-body-mass/:id',
    name: 'member-vital-sign-body-mass-detail',
    component: () => import('@/views/profile/ProfileVitalSignBodyMassDetail.vue'),
  },
  {
    path: '/account-setting',
    name: 'member-setting',
    component: () => import('@/views/profile/ProfileSetting.vue'),
  },
  {
    path: '/account',
    name: 'switch-account',
    component: () => import('@/views/profile/ProfileAccounts.vue'),
  },
  {
    path: '/account-update',
    name: 'account-update',
    component: () => import('@/views/profile/ProfileAccountUpdate.vue'),
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test/index.vue'),
  },
]
