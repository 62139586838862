const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const today = new Date()
const day = `0${today.getDate()}`.substr(-2)
const month = `0${today.getMonth() + 1}`.substr(-2)
const year = `${today.getFullYear()}`
const hour = `0${today.getHours()}`.substr(-2)
const minutes = `0${today.getMinutes()}`.substr(-2)
const seconds = `0${today.getSeconds()}`.substr(-2)

export default {
  getCurDayName() {
    return days[today.getDay()]
  },
  getCurDateTime() {
    return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
  },
  getCurTime() {
    return `${hour}:${minutes}`
  },
  getSQLCurDateNotDash() {
    return `${year}${month}${day}`
  },
  getSQLCurDate() {
    return `${year}-${month}-${day}`
  },
  getSQLCurDateTime() {
    return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`
  },
  getFullDate(dateInput, lang = 'th') {
    const tmp = dateInput.substring(0, 10).split('-')
    const tmpDay = Number(tmp[2])
    const tmpMonth = Number(tmp[1])
    let tmpYear = Number(tmp[0])
    let monthNames

    if (lang === 'th') {
      monthNames = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.']
      tmpYear = Number(tmpYear) + 543
    } else {
      monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }

    return `${tmpDay} ${monthNames[tmpMonth - 1]} ${tmpYear}`
  },
  getMonthName(id, lang = 'th') {
    // let monthNames = ['JAN', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    if (lang === 'th') {
      // monthNames = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม']
      monthNames = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.']
    }
    const idMonth = parseInt(id, 0)
    return monthNames[idMonth - 1]
  },
  getYearName(dataInput, lang = 'th') {
    if (lang === 'th') {
      // eslint-disable-next-line radix
      return parseInt(dataInput) + 543
    }
    return dataInput
  },
  IntNull(dataInput) {
    if (dataInput === '') {
      return 0
    }
    return parseInt(dataInput, 0)
  },
  FloatNull(dataInput) {
    if (dataInput === '') {
      return 0
    }
    return parseFloat(dataInput, 2)
  },
  TimeNull(dataInput) {
    if (dataInput === '') {
      return '00'
    }
    return dataInput
  },
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
}
